export default {
    companies: {
        title: 'Escolas',
        count: 0,
        icon: 'mdi-flag',
        path: 'companies_admin'
    },
    emotions: {
        title: 'Emoções',
        count: 0,
        icon: 'mdi-emoticon-neutral-outline',
        path: 'emotions_admin'
    },
    actors: {
        title: 'Atores',
        count: 0,
        icon: 'mdi-human-handsdown',
        path: 'actors_admin'
    },
    reasons: {
        title: 'Motivos',
        count: 0,
        icon: 'mdi-fire',
        path: 'reasons_admin'
    }
};
