<template>
    <v-container class="sector">
        <v-layout justify-center align-center>
            <v-flex xs12>
                <v-container class="dashs">
                    <v-row>
                        <v-col
                            v-for="(dash, index) of dashs"
                            :key="dash.sector"
                            class="dash-item"
                            :class="firstItemComputed(index)">
                            <div class="icon">
                                <v-icon x-large color="black">{{ dash.icon }}</v-icon>
                            </div>
                            <div class="count">{{ dash.count }}</div>
                            <div class="title">{{ dash.title }}</div>
                            <div class="box-button">
                                <v-btn class="btn-gear" @click="goTo(dash.path)">
                                    Gerenciar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import dashConfig from '../configs/dash.admin.config';

    export default {
        name: 'DashAdmin',
        data() {
            return {
                showDashs: false,
                dashs: []
            };
        },
        mounted() {
            this.serviceLoadingCompanyStatistics();
        },
        computed: {
            firstItemComputed() {
                return (index) => {
                    return {
                        'dash-item-first': !index ? true : false
                    };
                };
            }
        },
        methods: {
            goTo(path) {
                this.$router.push({ path });
            },
            dataAdapter(data) {
                const aux = [];

                let key = null,
                    value = null,
                    field = null;

                data.forEach((e) => {
                    key = e.key;
                    value = e.value;

                    field = dashConfig[key];
                    field.count = value;

                    aux.push(field);
                });

                return aux;
            },
            dataFilter(data) {
                const aux = [];

                const fields = ['companies', 'emotions', 'actors', 'reasons'];

                Object.keys(data).forEach((key) => {
                    if (fields.includes(key)) {
                        aux.push({
                            key,
                            value: data[key]
                        });
                    }
                });

                return aux;
            },
            serviceLoadingCompanyStatistics() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['admin']
                    .getAdminStatistics()
                    .then((result) => {
                        const record = result.data.doc;

                        const dataFiltered = this.dataFilter(record);

                        const dataAdpted = this.dataAdapter(dataFiltered);

                        this.dashs = dataAdpted;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.showDashs = true;
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .sector {
        width: 100%;
    }

    .sector-flex {
        width: 100%;
    }

    .sector .sector-head-title {
        margin-bottom: 30px;
        text-align: center;
    }

    .dashs .dash-item {
        position: relative;
        margin: 20px;
        height: 180px;
        background-color: #fff;
    }

    .dashs .dash-item .count {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 50px;
    }
    /*
    .dashs .dash-item .icon {
    } */

    .dashs .dash-item .title {
        position: absolute;
        font-size: 16px !important;
        font-weight: normal !important;
        color: #000;
        right: 20px;
        top: 70px;
    }

    .dashs .dash-item .box-button {
        position: relative;
        top: 55px;
        border-top: 1px solid #e0e0e0;
    }

    .btn-gear {
        margin-top: 10px;
        text-transform: none !important;
    }
</style>
